import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import MenuIcon from '@material-ui/icons/Menu';

const styles = (theme) => ({
  logo: {
    height: '36px',
    width: '36px',
    marginRight: '10px'
  },
});

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null
      }
    };
  }

  openMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl
      }
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null
      }
    });
  };

  render() {
    // Properties
    const {
      performingAction,
      theme,
      classes
    } = this.props;

    const {
      menu
    } = this.state;

    const menuItems = [
      {
        children: 'About',
        onClick: () => {
          this.closeMenu();

          this.props.onAboutClick();
        }
      },
      {
        children: 'Download on App Store',
        onClick: () => {
          this.closeMenu();

          this.props.onDownloadAppStore();
        }
      }
    ];

    return (
      <AppBar color="primary" position="static">
        <Toolbar variant={theme.dense ? 'dense' : 'regular'}>
          <Box display="flex" flexGrow={1}>
            <Link to="/">
              <img src={'/logo192.png'} className={classes.logo} alt="logo" />
            </Link>
            <Typography color="inherit" variant="h6">{process.env.REACT_APP_TITLE}</Typography>
          </Box>

          <IconButton color="inherit" disabled={performingAction} onClick={this.openMenu}>
            <MenuIcon />
          </IconButton>

          <Menu anchorEl={menu.anchorEl} open={Boolean(menu.anchorEl)} onClose={this.closeMenu}>
            {menuItems.map((menuItem, index) => {
              return (
                <MenuItem key={index} dense={theme.dense} disabled={performingAction} onClick={menuItem.onClick}>
                  {menuItem.children}
                </MenuItem>
              );
            })}
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

Bar.defaultProps = {
  performingAction: false
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  user: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onDownloadAppStore: PropTypes.func.isRequired
};

export default withStyles(styles)(Bar);
