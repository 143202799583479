import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import PrivacyMarkdown from './Privacy.md';

class PrivacyContent extends Component {

  constructor() {
    super();
    this.state = { markdown: '' };
  }

  componentWillMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(PrivacyMarkdown).then(res => res.text()).then(text => this.setState({ markdown: text }));
  }

  render() {
    const { markdown } = this.state;
    return <ReactMarkdown source={markdown} />;
  }
}

export default withRouter(PrivacyContent);
