import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      "light": "#ff9c9b",
      "main": "#ff7373",
      "dark": "#ff4e4d",
      "contrastText": "#fff"
    },
    secondary: {
      "light": "#b1fffd",
      "main": "#73ffff",
      "dark": "#00cacf",
      "contrastText": "#000"
    },
    type: 'light',
  },
  type: 'light',
  dense: false
});

const theming = {};

theming.defaultTheme = defaultTheme;

export default theming;
