import React, { Component } from 'react'

import PropTypes from 'prop-types';

import { Switch, Route } from "react-router-dom";

import HomeContent from '../HomeContent';
import PrivacyContent from '../PrivacyContent';
import TermsContent from '../TermsContent';
import NotFoundContent from '../NotFoundContent';

class Router extends Component {
  render() {
    // Properties
    const {
      user,
      products
    } = this.props;

    // Functions
    const {
      openSnackbar
    } = this.props;

    return (
      <Switch>
        <Route path="/" exact>
          <HomeContent
            user={user}
            products={products}

            openSnackbar={openSnackbar}
          />
        </Route>


        <Route path="/privacy" exact>
          <PrivacyContent/>
        </Route>

        <Route path="/terms" exact>
          <TermsContent/>
        </Route>

        <Route>
          <NotFoundContent />
        </Route>
      </Switch>
    )
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  products: PropTypes.array,

  // Functions
  openSnackbar: PropTypes.func.isRequired
};

export default Router;
