import React, { Component } from 'react';

import readingTime from 'reading-time';

import { BrowserRouter } from "react-router-dom";

import { MuiThemeProvider } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';

import { auth, firestore } from '../../firebase';
import theming from '../../services/theming';

import ErrorBoundary from '../ErrorBoundary';
import LaunchScreen from '../LaunchScreen';
import Bar from '../Bar';
import Router from '../Router';
import DialogHost from '../DialogHost';

const initialState = {
  ready: false,
  performingAction: false,
  theme: theming.defaultTheme,
  user: null,
  products: null,

  aboutDialog: {
    open: false
  },

  snackbar: {
    autoHideDuration: 0,
    message: '',
    open: false
  }
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  resetState = (callback) => {
    this.setState({
      ready: true,
      theme: theming.defaultTheme,
      user: null,
      products: null
    }, callback);
  };

  setTheme = (theme, callback) => {
    if (!theme) {
      this.setState({
        theme: theming.defaultTheme
      }, callback);

      return;
    }

    this.setState({
      theme: theming.createTheme(theme)
    }, callback);
  };

  openURL = (url) => {
    if (!url) {
      return;
    }

    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  openDialog = (dialogId, callback) => {
    const dialog = this.state[dialogId];

    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = true;

    this.setState({ dialog }, callback);
  };

  closeDialog = (dialogId, callback) => {
    const dialog = this.state[dialogId];

    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = false;

    this.setState({ dialog }, callback);
  };

  closeAllDialogs = (callback) => {
    this.setState({
      aboutDialog: {
        open: false
      },
    }, callback);
  };

  openSnackbar = (message, autoHideDuration = 2, callback) => {
    this.setState({
      snackbar: {
        autoHideDuration: readingTime(message).time * autoHideDuration,
        message,
        open: true
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  closeSnackbar = (clearMessage = false) => {
    const {
      snackbar
    } = this.state;

    this.setState({
      snackbar: {
        message: clearMessage ? '' : snackbar.message,
        open: false
      }
    });
  };

  render() {
    const {
      ready,
      performingAction,
      theme,
      user,
      products
    } = this.state;

    const {
      aboutDialog
    } = this.state;

    const {
      snackbar
    } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />

        <ErrorBoundary>
          {!ready &&
            <LaunchScreen />
          }

          {ready &&
            <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
              <Bar
                performingAction={performingAction}
                theme={theme}
                user={user}
                products={products}

                onAboutClick={() => this.openDialog('aboutDialog')}
                onDownloadAppStore={() => this.openURL('https://apps.apple.com/us/app/iscale-kitchen-scale/id1455602485')}
              />

              <Router
                user={user}
                products={products}

                openSnackbar={this.openSnackbar}
              />

              <DialogHost
                user={user}
                dialogs={
                  {
                    aboutDialog: {
                      dialogProps: {
                        open: aboutDialog.open,

                        onClose: () => this.closeDialog('aboutDialog')
                      },

                      props: {
                        theme: theme,
                        user: user
                      }
                    }
                  }
                }
              />

              <Snackbar
                autoHideDuration={snackbar.autoHideDuration}
                message={snackbar.message}
                open={snackbar.open}

                onClose={this.closeSnackbar}
              />
            </BrowserRouter>
          }
        </ErrorBoundary>
      </MuiThemeProvider>
    );
  }

  componentDidMount() {
    this.onAuthStateChangedObserver = auth.onAuthStateChanged((user) => {
      // The user is not signed in or doesn’t have a user ID.
      if (!user || !user.uid) {
        if (this.productDocumentSnapshotListener) {
          this.productDocumentSnapshotListener();
        }

        this.resetState();

        return;
      }

      this.setState({
        user: user,
      });

      // The user is signed in, begin retrieval of external product data.
      this.productDocumentSnapshotListener = firestore.collection('products').orderBy('order').onSnapshot((snapshot) => {
        if (!snapshot || snapshot.isEmpty) {
          if (this.productDocumentSnapshotListener) {
            this.productDocumentSnapshotListener();
          }

          return;
        }

        const products = snapshot.docs.map(doc => {
          return {
            id: doc.id,
            data: doc.data()
          }
        });

        this.setState({
          ready: true,
          products: products
        });

      }, (error) => {
        this.resetState(() => {
          const code = error.code;
          const message = error.message;

          switch (code) {
            default:
              this.openSnackbar(message);
              return;
          }
        });
      });
    }, (error) => {
      this.resetState(() => {
        const code = error.code;
        const message = error.message;

        switch (code) {
          default:
            this.openSnackbar(message);
            return;
        }
      });
    });
  }

  componentWillUnmount() {
    if (this.onAuthStateChangedObserver) {
      this.onAuthStateChangedObserver();
    }

    if (this.productDocumentSnapshotListener) {
      this.productDocumentSnapshotListener();
    }
  }
}

export default App;
