import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import EmptyState from '../EmptyState';
import ProductCard from '../ProductCard';

import authentication from '../../services/authentication';
import { remoteConfig } from '../../firebase';

class HomeContent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      productsFooter: ''
    };
  }

  signInAnonymously = () => {
    const {
      user
    } = this.props;

    if (user) {
      return;
    }

    authentication.signInAnonymously().then((value) => {
      // do nothing
    }).catch((reason) => {
      if (reason) {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          case 'auth/expired-action-code':
          case 'auth/invalid-email':
          case 'auth/user-disabled':
            this.props.openSnackbar(message);
            break;

          default:
            this.props.openSnackbar(message);
            return;
        }
      }
    }).finally(() => {
    });
  };

  render() {
    // Properties
    const {
      products
    } = this.props;

    const {
      productsFooter
    } = this.state;

    if (!products || products.length === 0) {
      return (
        <EmptyState
          title={process.env.REACT_APP_TITLE}
          description={process.env.REACT_APP_DESCRIPTION}
        />
      );
    }

    return (
      <Box m={2}>
        <Grid container justify="center"  spacing={2}>
        {
          Object.keys(products).map((index) => {
            const { id, data } = products[index];

            return (
              <Grid key={id} item>
                <ProductCard product={data} />
              </Grid>
            )
          })
        }
        </Grid>
        <Typography variant="body2" color="textSecondary" justify="center">
          {productsFooter}
        </Typography>
      </Box>
    );
  }

  componentDidMount() {
    this.signInAnonymously();

    remoteConfig.fetchAndActivate()
    .then(() => {
      this.setState({
        productsFooter: remoteConfig.getString('productsFooter')
      })
    })
    .catch((err) => {
      console.error(err);
    });
  }
}

HomeContent.propTypes = {
  // Properties
  user: PropTypes.object,
  products: PropTypes.array
};

export default withRouter(HomeContent);
