import { auth } from '../firebase';

const authentication = {};

authentication.signInAnonymously = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      reject();

      return;
    }

    auth.signInAnonymously().then((value) => {
      const user = value.user;

      if (!user) {
        reject();

        return;
      }

      resolve(user);
    }).catch((reason) => {
      reject(reason);
    });
  });
};

export default authentication;
