import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AboutDialog from '../AboutDialog';

class DialogHost extends Component {
  render() {
    // Properties
    const {
      dialogs
    } = this.props;

    const aboutDialog = dialogs.aboutDialog;

    return (
      <>
        <AboutDialog
          dialogProps={aboutDialog.dialogProps}

          {...aboutDialog.props}
        />
      </>
    );
  }
}

DialogHost.propTypes = {
  // Properties
  user: PropTypes.object,
  dialogs: PropTypes.object.isRequired
};

export default DialogHost;
