import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';

const styles = (theme) => ({
  card: {
    maxWidth: 345,
    padding: theme.spacing(2),
  },
  media: {
    height: 140,
  },
  rating: {
    width: 200,
    display: 'flex',
    alignItems: 'center',
  }
});

class ProductCard extends Component {

  onClick = () => {
    const {
      product
    } = this.props;

    if (!product || !product.url) {
      return;
    }

    const win = window.open(product.url, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  render() {

    const {
      classes
    } = this.props;

    const {
      product
    } = this.props;

    return (
      <>
      { product &&
        <Card className={classes.card}>
          <CardActionArea  onClick={this.onClick}>
            <CardMedia
              className={classes.media}
              image={product.imageURL}
              title={product.title}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {product.title}
              </Typography>
              <div className={classes.rating}>
                <Rating
                  name="hover-feedback"
                  value={parseFloat(product.rating) || 0}
                  precision={0.5}
                />
                <Box ml={2}>
                  <Typography variant="body2" color="textSecondary">
                    {product.rating}
                  </Typography>
                </Box>
              </div>
              <Typography variant="h6" component="p">
                ${product.price.split(", ")[0]}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button fullWidth color="primary" variant="contained" onClick={this.onClick}>
            {'Buy On Amazon'}
            </Button>
          </CardActions>
        </Card>
      }
      </>
    );
  }
}

ProductCard.propTypes = {
  // Properties
  product: PropTypes.object,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCard);
